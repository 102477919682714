import './PreviewEmailModal.scss'
import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { GUESTLIST_API, stringifyParams } from 'misc/api/util'
import { getStoredToken } from 'misc/api/storedToken'

import { Modal, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'
import Loader from 'components/Loader'

const host =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''

const PreviewEmailModal = ({
  title,
  content,
  eventInformationVisible,
  guestlistId,
  startTime,
  emailButtonPreview,
  emailLogoPreview,
  endTime,
  location,
  handleClose
}) => {
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState(false)

  const token = getStoredToken()

  const iframeRef = useRef(null)

  return (
    <Modal modalName="PreviewEmailModal" size="medium">
      <Content>
        <iframe
          title="preview"
          src={`${host}${GUESTLIST_API}/email/${guestlistId}/preview${stringifyParams(
            { bearer_token: token.token }
          )}`}
          frameBorder={0}
          ref={iframeRef}
          className={clsx('PreviewEmailModalIframe', { isLoaded })}
          onLoad={() => void setIsLoaded(true)}
        />

        {!isLoaded ? (
          <div className="PreviewEmailModalLoader">
            <Loader />
          </div>
        ) : null}
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('cancel')}
          </Button>
        }
      />
    </Modal>
  )
}

export default PreviewEmailModal
