import './Text.scss'
import React from 'react'
import clsx from 'clsx'

type TextProps = {
  icon?: React.ReactNode
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  handleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  value?: string
  inputRef?: React.RefObject<HTMLInputElement>
  errored?: boolean
  readOnly?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

export const Text = ({
  icon,
  handleChange,
  handleBlur,
  handleFocus,
  value = '',
  inputRef,
  errored = false,
  readOnly = false,
  ...props
}: TextProps) => {
  return (
    <div
      className={clsx('Text', { 'is-errored': errored })}
      data-icon={icon ? true : false}>
      <input
        {...props}
        onBlur={handleBlur}
        value={value}
        onFocus={handleFocus}
        type="text"
        onChange={handleChange}
        autoComplete="disabled"
        readOnly={readOnly}
        ref={inputRef}
      />
      {icon}
    </div>
  )
}

export default Text
