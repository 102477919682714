/** @jsxImportSource @emotion/react */

import Modal, { Actions, Content, Header } from '../../Modal'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { Field } from 'misc/api/hooks/event.types'
import { Guest } from 'misc/api/types'
import { getConnectedFields } from '../helper'
import { FieldFactory } from './FieldFactory'
import { FieldValue, FieldWithValue } from '../types'
import { FieldType } from 'misc/field.types'
import { css } from '@emotion/react'
import { useState } from 'react'
import useUpdateGuestDetails from './useUpdateGuestDetails/useUpdateGuestDetails'
import Spinner from './Spinner'
import { ValidationError } from './useUpdateGuestDetails/useUpdateGuestDetails.types'

const getHeaderTitle = (fields: FieldWithValue[]) => {
  const firstNameField = fields.find(
    field => field.type === FieldType.FIRST_NAME
  )
  const lastNameField = fields.find(field => field.type === FieldType.LAST_NAME)

  let title = ''
  if (firstNameField) {
    title += firstNameField.value
  }
  if (lastNameField) {
    title += ` ${lastNameField.value}`
  }

  return title.trim()
}

interface EditGuestDetailsModalProps {
  handleCloseModal: () => void
  onChangesSaved: (guestData: Record<string, FieldValue>) => void
  fields: Field[]
  isLeader: boolean
  guest: Guest
}
export const EditGuestDetailsModal = ({
  handleCloseModal,
  fields,
  isLeader,
  guest,
  onChangesSaved
}: EditGuestDetailsModalProps) => {
  const { t } = useTranslation()

  const dataFields = getConnectedFields(guest, fields)

  const [formValues, setFormValues] = useState<FieldWithValue[]>(dataFields)
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState<(string | null)[]>(
    dataFields.map(() => null)
  )
  const hasAnyErrors = errors.some(error => error)
  const [
    errorMessageUnrelatedToValidation,
    setErrorMessageUnrelatedToValidation
  ] = useState<string | null>(null)
  const { mutation: updateGuestDetailsMutation } = useUpdateGuestDetails()

  const isSaveDisabled =
    !isDirty || hasAnyErrors || updateGuestDetailsMutation.isPending

  const handleSave = async () => {
    const guestId = guest.id

    const guestData = Object.fromEntries(
      formValues
        .filter(field => field.value !== null && field.value !== undefined)
        .map(field => [field.key, field.value])
    )

    const response = await updateGuestDetailsMutation.mutateAsync({
      guestId,
      guest_data: guestData
    })

    if (response.error) {
      if (response.error instanceof ValidationError) {
        const validationErrors = response.error.validationErrors

        setErrors(prev =>
          prev.map((prevError, prevIndex) => {
            const errorEntry =
              validationErrors[Object.keys(validationErrors)[0]]

            return errorEntry.guest_data_key === formValues[prevIndex].key
              ? JSON.stringify(errorEntry.error)
              : prevError
          })
        )
      } else {
        setErrorMessageUnrelatedToValidation('Something went wrong')
      }

      return
    }

    onChangesSaved(guestData)
    handleCloseModal()
  }

  return (
    <Modal size="medium">
      <div className="ManagePartyModal">
        <Header>
          <span>{getHeaderTitle(dataFields)}</span>
          {isLeader && (
            <span
              css={css`
                font-weight: 400;
              `}>
              {/* empty space char */}
              {' - '}
              MAIN GUEST
            </span>
          )}
        </Header>
        <Content>
          {formValues.map((field, index) => {
            return (
              <FieldFactory
                key={index}
                field={field}
                onChange={(value, error) => {
                  setIsDirty(true)
                  setErrors(prev => {
                    return prev.map((prevError, prevIndex) =>
                      prevIndex === index ? error : prevError
                    )
                  })

                  setFormValues(prev => {
                    return prev.map((prevField, prevIndex) =>
                      prevIndex === index ? { ...prevField, value } : prevField
                    )
                  })
                }}
                error={errors[index]}
              />
            )
          })}
          {errorMessageUnrelatedToValidation && (
            <p
              css={css`
                display: flex;
                padding: 16px;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 4px;
                border: 1px solid #fe2f1f;
                background: rgba(254, 47, 31, 0.2);
              `}>
              {errorMessageUnrelatedToValidation}
            </p>
          )}
        </Content>
        <Actions
          leftActions={
            <Button type="secondary" onClick={handleCloseModal}>
              {t('cancel')}
            </Button>
          }
          rightActions={
            <div
              css={css`
                display: flex;
                align-items: center;
                position: relative;
              `}>
              <div
                css={css`
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                `}>
                <Spinner />
              </div>
              <Button
                css={css`
                  opacity: ${updateGuestDetailsMutation.isPending ? 0 : 1};
                `}
                type="primary"
                onClick={handleSave}
                disabled={isSaveDisabled}>
                {t('save')}
              </Button>
            </div>
          }
        />
      </div>
    </Modal>
  )
}
