import { FieldType } from 'misc/field.types'
import { z } from 'zod'

export const fieldValidators = {
  [FieldType.FIRST_NAME]: z.string().optional(),
  [FieldType.LAST_NAME]: z.string().optional(),
  [FieldType.COMPANY_NAME]: z.string().optional(),
  [FieldType.EMAIL]: z
    .string()
    .email({
      message: 'Incorrect e-mail address format'
    })
    .optional(),
  [FieldType.PHONE_NUMBER]: z
    .string()
    .optional()
    .refine(val => !val || /^[+][0-9]+$/.test(val), {
      message: 'Incorrect phone number format'
    }),
  [FieldType.SELECT_SINGLE_CHOICE]: z.string().optional(),
  [FieldType.SELECT_MULTIPLE_CHOICE]: z
    .array(z.string())
    .min(1, {
      message: 'At least one option should be selected'
    })
    .optional(),
  [FieldType.TEXT]: z.string().optional(),
  [FieldType.NUMBER]: z
    .number({
      message: 'Incorrect number format'
    })
    .optional(),
  [FieldType.BOOL]: z.boolean().optional()
} as const
