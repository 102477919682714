import axios from 'axios'

function handleSeeOther(error) {
  const data = error.response && error.response.data

  if (data && data.see_other) {
    window.location = data.see_other
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(null, handleSeeOther)

export const setAuthorizationHeader = resp => {
  const { token_type, token } = resp.data

  if (token_type && token) {
    axios.defaults.headers.common['Authorization'] = `${token_type} ${token}`
  }

  return resp
}

export function makeCancelable(promise) {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      resp => (hasCanceled ? reject({ canceled: true }) : resolve(resp)),
      err => (hasCanceled ? reject({ canceled: true }) : reject(err))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    }
  }
}

export const GUESTLIST_API = process.env.REACT_APP_GUESTLIST_API
export const REGISTRATION_API = process.env.REACT_APP_REGISTRATION_API
export const LEAD_CAPTURE_FORM_URL = process.env.REACT_APP_LEAD_CAPTURE_FORM_URL
export const EXTENDED_LANDING_PAGES_API =
  process.env.REACT_APP_EXTENDED_LANDING_PAGES_API
export const SMS_API = process.env.REACT_APP_SMS_API

export const ResponseObject = function(status = null, data = null) {
  this.status = status
  this.data = data
}

ResponseObject.prototype.assign = (status, data) => {
  this.status = status
  this.data = { ...this.data, ...data }
}

ResponseObject.prototype.append = (status, data) => {
  this.status = status
  this.data = [...this.data, data]
}

ResponseObject.prototype.replace = (status, data) => {
  this.status = status
  this.data = data
}

export const getResponsePromise = ({ status, data }, expectedStatus = 200) =>
  new Promise((resolve, reject) => {
    const response = { status, data }
    if (
      status === expectedStatus ||
      (Array.isArray(expectedStatus) && expectedStatus.includes(status))
    ) {
      resolve(response)
    }
    reject(response)
  })

export const fetchAPI = baseURL => ({
  url,
  method = 'get',
  headers = {},
  params = null,
  data = null,
  responseType = 'json',
  cancelToken = undefined
}) =>
  axios({
    method,
    headers,
    baseURL,
    url,
    data,
    params,
    responseType,
    cancelToken
  })

export const stringifyParams = object =>
  (str => (str ? `?${str}` : ''))(
    Object.entries(object)
      .filter(([, value]) => value != null)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
  )

export const getCancelSource = () => {
  return axios.CancelToken.source()
}
