import './Creator.scss'
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { availableFields } from 'components/Contexts/UndoProvider/helpers/data'
import { renderIcon } from 'components/FormFactory/Icons'

const Creator = ({
  id,
  factoryId,
  debug,
  handleCloseCreator,
  handleCreateField
}) => {
  const { t } = useTranslation()
  const creatorRef = useRef(null)

  const createNewField = field => event => {
    const key = `${field.key}__${uuidv4()}`
    const guest_type = factoryId === 0 ? 'leader' : 'follower'
    const label = t(field.label)

    const data = field.data
      ? {
          ...field.data,
          options: field.data.options
            ? field.data.options.map((option, i) => ({
                ...option,
                value: `${t(option.value)} ${i + 1}`
              }))
            : null
        }
      : null

    const newField = {
      ...field,
      data,
      label,
      key,
      guest_type
    }

    handleCreateField(newField, factoryId)
    handleCloseCreator()
  }

  useEffect(() => {
    const handleClick = event => {
      if (creatorRef.current && creatorRef.current.contains(event.target)) {
        // We can't use event.stopPropagation() here, because it will prevent the onClick event from firing on the buttons
        return
      }
      handleCloseCreator()
    }

    document.addEventListener('click', handleClick, true) // Capture phase

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [handleCloseCreator])

  return (
    <div
      className="Creator"
      data-debug={debug}
      ref={creatorRef}
      onClick={handleCloseCreator}>
      {cloneDeep(availableFields).map((field, index) => (
        <button onClick={createNewField(field)} key={index}>
          {renderIcon(field.type, field.visual_placing)}
          {t(field.label)}
        </button>
      ))}
    </div>
  )
}

export default Creator

Creator.propTypes = {
  handleCreateField: PropTypes.func.isRequired,
  handleCloseCreator: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  debug: PropTypes.bool
}
