export enum FieldType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOL = 'BOOL',
  SELECT_SINGLE_CHOICE = 'SELECT_SINGLE_CHOICE',
  SELECT_MULTIPLE_CHOICE = 'SELECT_MULTIPLE_CHOICE'
}

export enum VisualPlacing {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOL = 'BOOL',
  SELECT_BOX = 'SELECT_BOX',
  RADIO_SELECTION = 'RADIO_SELECTION'
}

export enum FieldKey {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  COMPANY_NAME = 'company_name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  TEXT = 'text',
  NUMBER = 'number',
  BOOL = 'checkbox',
  SELECT_SINGLE_CHOICE = 'dropdown',
  SELECT_MULTIPLE_CHOICE = 'multiple_selection_checkbox',
  RADIO_SELECTION = 'radiobutton'
}

export enum FieldLabel {
  FIRST_NAME = 'first name',
  LAST_NAME = 'last name',
  COMPANY_NAME = 'company name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone number',
  TEXT = 'textfield',
  NUMBER = 'number',
  BOOL = 'checkbox',
  SELECT_SINGLE_CHOICE = 'dropdown',
  SELECT_MULTIPLE_CHOICE = 'multiple selection'
}
