import API from 'misc/api'
import React, { createContext, useContext, useState } from 'react'

import { getStoredToken, storeToken } from 'misc/api/storedToken'

import { setAuthorizationHeader } from 'misc/api/util'

export const AuthContext = createContext(null)

export const useAuth = () => useContext(AuthContext)

const AuthProvider = props => {
  const [bearerToken, setBearerToken] = useState(null)

  const fetchBearerToken = params => {
    const storedToken = getStoredToken()

    if (storedToken) {
      setBearerToken(storedToken.token)
      return Promise.resolve()
    }

    return API.authorize(params)
      .then(setAuthorizationHeader)
      .then(resp => {
        const jwtToken = resp.data
        storeToken(jwtToken)
        setBearerToken(jwtToken.token)
        return resp
      })
      .catch(console.error)
  }

  return (
    <AuthContext.Provider
      {...props}
      value={{ bearerToken, fetchBearerToken }}
    />
  )
}

export default AuthProvider
