import moment from 'moment-timezone'
import './EventResult.scss'

// Hooks
import { useCheckIn } from 'misc/api'
import { Trans, useTranslation } from 'react-i18next'
import { useSearch } from './useSearch'

// Components
import AccordionTable from 'components/AccordionTable'
import EmptyState from 'components/AccordionTable/EmptyState'
import ActiveEventHeader from 'components/ActiveEventHeader'
import Wrapper from 'components/Wrapper'
import { EventResponse, Guestlist } from 'misc/api/hooks/event.types'
import { Party } from 'misc/api/types'
import { FieldValue } from 'components/Modals/ManagePartyModal/types'

interface EventResultProps {
  deactivateEvent: () => void
  event: EventResponse
  token: string
  lead_capture_token: string
  publishEvent: () => void
  fetchEvent: (eventId: number) => void
}
const EventResult = ({
  deactivateEvent,
  event,
  token,
  lead_capture_token,
  publishEvent,
  fetchEvent
}: EventResultProps) => {
  const { t } = useTranslation()

  const [
    { parties, checkinUrl, fields },
    { updateParty, cancelParty }
  ] = useCheckIn(event, fetchEvent.bind(null, event.event))
  const [{ search }, { setSearch, filterGuests }] = useSearch()

  const updateState = (party: Party) =>
    new Promise<Party>(resolve => {
      setTimeout(() => {
        resolve(party)
      }, 150)
    }).then(party => {
      return updateParty(party)
    })

  const updateCancelledState = (party: Party) =>
    new Promise<Party>(resolve => {
      setTimeout(() => {
        resolve(party)
      }, 150)
    }).then(party => cancelParty(party))

  const toggleCancelGuest = (guestId: number, partyId: number) => {
    const party = parties.find(p => p.id === partyId)

    if (!party) {
      return
    }

    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    // Handles cancel and undo state on guest
    const guest = party.guests[guestIndex]
    party.guests[guestIndex].canceled = !guest.canceled
    updateCancelledState(party)
  }

  const deleteGuest = (guestId: number, partyId: number) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    party.guests.splice(guestIndex, 1)
    updateState(party)
  }

  const updateGuestDetailsLocally = (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    party.guests[guestIndex].guest_data = guestData
  }

  const cancelPartyHandler = (partyId: number) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    updateCancelledState({
      ...party,
      guests: party.guests.map(guest => ({
        ...guest,
        canceled: true
      }))
    })
  }

  const restorePartyHandler = (partyId: number) => {
    const party = parties.find(party => party.id === partyId)

    if (!party) {
      return
    }

    updateCancelledState({
      ...party,
      guests: party.guests.map(guest => ({
        ...guest,
        canceled: false
      }))
    })
  }

  const emptyStateMessage =
    event.state === 'DRAFT' ? (
      <Trans
        i18nKey="eventNotActive"
        values={{
          draft: t('states.DRAFT.headingActive').toUpperCase(),
          open: t('states.ACTIVE.headingDraft').toUpperCase()
        }}>
        The event is inactive, change event status from{' '}
        <span className="EventMark">draft</span> to{' '}
        <span className="EventMark">open</span> to start collecting sign-ups
      </Trans>
    ) : parties.length === 0 ? (
      t('noSignupsText')
    ) : (
      t('noMatchingGuestsText')
    )

  const EventResultSection = ({ guestlist }: { guestlist: Guestlist }) => {
    const partiesInGuestlist = parties.filter(
      party => party.guestlist === guestlist.id
    )
    const filteredGuests = filterGuests(partiesInGuestlist)
    const numberOfAttendingGuests = partiesInGuestlist.reduce(
      (acc, party) => acc + party.guests.filter(g => !g.canceled).length,
      0
    )

    return (
      <section className="EventResultSection">
        {event.timeslots_enabled ? (
          <header className="EventResultHeader">
            <div className="EventResultData">
              {guestlist.name ? (
                <h3 className="EventResultName">{guestlist.name}</h3>
              ) : null}

              <div>
                <time
                  className="EventResultTime"
                  dateTime={guestlist.start_time}>
                  {moment
                    .parseZone(guestlist.start_time)
                    .format('YYYY-MM-DD, HH:mm')}
                </time>
                –
                <time className="EventResultTime" dateTime={guestlist.end_time}>
                  {moment.parseZone(guestlist.end_time).format('HH:mm')}
                </time>
              </div>
            </div>

            <div className="EventResultMeta">
              <div className="EventResultMetaColumn">
                <div className="EventResultMetaNumber">
                  {numberOfAttendingGuests}
                </div>
                {t('guests')}
              </div>
              <div className="EventResultMetaColumn">
                <div className="EventResultMetaNumber">
                  {guestlist.guest_max_limit ||
                    '–' /* null and 0 should render a dash */}
                </div>
                {t('guest limit')}
              </div>
            </div>
          </header>
        ) : null}

        {filteredGuests.length > 0 ? (
          <AccordionTable
            event={event}
            fields={fields}
            parties={filteredGuests}
            toggleCancelGuest={toggleCancelGuest}
            cancelParty={cancelPartyHandler}
            restoreParty={restorePartyHandler}
            deleteGuest={deleteGuest}
            debug={false}
            updateGuestDetails={updateGuestDetailsLocally}
          />
        ) : (
          <EmptyState
            message={t(
              partiesInGuestlist.length > 0 && search
                ? 'no results'
                : 'empty guestlist'
            )}
          />
        )}
      </section>
    )
  }

  return (
    <div className="EventResult">
      <ActiveEventHeader
        event={event}
        token={token}
        lead_capture_token={lead_capture_token}
        checkinUrl={checkinUrl}
        deactivateEvent={deactivateEvent}
        publishEvent={publishEvent}
        handleSearchInput={(e: { target: { value: string } }) =>
          setSearch(e.target.value)
        }
        search={search}
      />
      <Wrapper className="EventResultWrapper" flex={undefined}>
        {parties.length === 0 ? (
          <EmptyState message={emptyStateMessage} />
        ) : (
          <>
            <header className="AccordionHeader">
              <h1>{t('guestlist')}</h1>
            </header>

            {event.guestlists.map((guestlist, i) => (
              <EventResultSection key={i} guestlist={guestlist} />
            ))}
          </>
        )}
      </Wrapper>
    </div>
  )
}

export default EventResult
