import { useState, useEffect, useContext } from 'react'
import API from 'misc/api'
import { makeCancelable } from 'misc/api/util'
import { ErrorContext } from '../../../components/Contexts/ErrorProvider'
import { EventResponse } from './event.types'

const sortFields = ({ fields }: EventResponse) => {
  const sortByOrderIndex = (fields: EventResponse['fields']) =>
    fields.sort((field, nextField) => {
      if (field.order_index < nextField.order_index) {
        return -1
      } else if (field.order_index > nextField.order_index) {
        return 1
      }

      return 0
    })
  const leaderFields = sortByOrderIndex(
    fields.filter(({ guest_type }) => guest_type === 'leader')
  )
  const followerFields = sortByOrderIndex(
    fields.filter(({ guest_type }) => guest_type === 'follower')
  )
  return [...leaderFields, ...followerFields]
}

const setGuestlistsKey = (
  event: EventResponse | null
): EventResponse | null => {
  if (!event) {
    return null
  }

  const guestlists = event?.guestlists.map((guestlist, i) => ({
    ...guestlist,
    key: i /* this is used to delete/modify the correct event no matter UI sorting */
  }))

  const modifiedEvent: EventResponse = { ...event }

  if (guestlists) {
    modifiedEvent.guestlists = guestlists
  }

  return modifiedEvent
}

interface UseEventState {
  event: EventResponse | null
  backgrounds: any[]
  previewLink: string | null
  isPublished: boolean
  isDeleted: boolean
  isLoading: boolean
  token: string | null
  lead_capture_token: string | null
}

type UseEventReturn = [
  UseEventState,
  {
    deactivateEvent: () => void
    deleteEvent: () => void
    duplicateEvent: (event: EventResponse) => Promise<any>
    fetchBackgrounds: (templateId: number | null, token: string) => void
    fetchEvent: (id: string) => void
    fetchPreviewLink: (token: string) => void
    publishEvent: () => void
    restoreEvent: (token: string) => void
    updateEvent: (event: EventResponse) => void
  }
]

export const useEvent = (id: string): UseEventReturn => {
  const [event, _setEvent] = useState<EventResponse | null>(null)
  const [backgrounds, setBackgrounds] = useState([])
  const [token, setToken] = useState(null)
  const [lead_capture_token, setLeadCaptureToken] = useState(null)
  const [previewLink, setPreviewLink] = useState(null)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { setError } = useContext(ErrorContext)

  const setEvent = (event: EventResponse | null) => {
    if (event) {
      event.fields = sortFields(event)
    }
    const eventWithGuestlistsKey = setGuestlistsKey(event)
    _setEvent(eventWithGuestlistsKey)
  }

  const isPublished = !!(event && event.published)

  const fetchEvent = (id: string) => {
    setIsLoading(true)
    const { cancel, promise } = makeCancelable(API.getEventDashboardInfo(id))
    promise
      .then(({ data, status }) => {
        if (status === 200) {
          setEvent(data)
        }

        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })

    return cancel
  }

  const fetchBackgrounds = (templateId: number | null, token: string) => {
    if (!templateId) return

    const { cancel, promise } = makeCancelable(
      API.getTemplateBackgrounds(templateId, token)
    )
    promise
      .then(({ data, status }) => {
        if (status === 200) {
          setBackgrounds(data)
        }
      })
      .catch(console.error)

    return cancel
  }

  const fetchSignupToken = (eventId: string) => {
    const { cancel, promise } = makeCancelable(API.getSignupToken(eventId))
    promise
      .then(({ data, status }) => {
        if (status === 200) {
          setToken(data.token)
          setLeadCaptureToken(data.lead_capture_token)
        }
      })
      .catch(console.error)

    return cancel
  }

  const fetchPreviewLink = (token: string) => {
    const { cancel, promise } = makeCancelable(API.getPreviewLink(token))
    promise
      .then(({ data, status }) => {
        if (status === 200) {
          setPreviewLink(data)
        }
      })
      .catch(console.error)

    return cancel
  }

  const restoreEvent = (token: string) => {
    API.restoreEvent(token).catch(console.error)
  }

  const duplicateEvent = (event: EventResponse) => {
    setIsLoading(true)
    return API.duplicateDashboardInfo(event)
      .then(resp => {
        if (resp.status === 201) {
          setEvent(resp.data)
        }

        return resp
      })
      .finally(() => setIsLoading(false))
  }

  const modifyPublishedStatus = (published: boolean) => {
    setIsLoading(true)
    API.putEventDashboardInfo(event?.event, { ...event, published })
      .then(({ data, status }) => {
        if (status === 200) {
          setEvent(data)
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const publishEvent = () => modifyPublishedStatus(true)
  const deactivateEvent = () => modifyPublishedStatus(false)

  const deleteEvent = () => {
    setIsLoading(true)
    API.deleteEventDashboardInfo(event?.event)
      .then(({ data, status }) => {
        if (status === 200) {
          setIsDeleted(true)
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const updateEvent = (event: EventResponse) => {
    setIsLoading(true)
    return API.putEventDashboardInfo(event.event, event)
      .then(({ data, status }) => {
        if (status === 200) {
          setEvent(data)
          setError({})
        }
      })
      .catch(e => {
        setError(e.response)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    const cancel = fetchEvent(id)
    return cancel
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const eventTemplate = event ? event.template : null

  useEffect(() => {
    if (id != null) {
      const cancel = fetchSignupToken(id)
      return cancel
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!eventTemplate || !token) return

    const cancelPreviewLink = fetchPreviewLink(token)
    const cancelBackgrounds = fetchBackgrounds(eventTemplate, token)

    return () =>
      [cancelPreviewLink, cancelBackgrounds].forEach(cancel => {
        if (typeof cancel === 'function') cancel()
      })
  }, [eventTemplate, token]) // eslint-disable-line react-hooks/exhaustive-deps

  const state = {
    event,
    backgrounds,
    previewLink,
    isPublished,
    isDeleted,
    isLoading,
    token,
    lead_capture_token
  }
  const handlers = {
    deactivateEvent,
    deleteEvent,
    duplicateEvent,
    fetchBackgrounds,
    fetchEvent,
    fetchPreviewLink,
    publishEvent,
    restoreEvent,
    updateEvent
  }
  return [state, handlers]
}
