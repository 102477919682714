import { useState, useEffect, useCallback } from 'react'
import API from 'misc/api'
import { Party } from 'misc/api/types'
import { EventResponse } from './event.types'

const noop = () => {}

interface State {
  parties: Party[]
  checkinUrl: string | null
  fields: EventResponse['fields']
}

interface Handlers {
  updateParty: (party: Party) => void
  cancelParty: (party: Party) => void
}

export const useCheckIn = (
  event: EventResponse | null,
  updateCallback: () => void = noop
): [State, Handlers] => {
  const [parties, setParties] = useState<Party[]>([])
  const [orgId, setOrgId] = useState<number | null>(null)
  const [checkinUrl, setCheckinUrl] = useState<string | null>(null)

  const fetchOrgId = () =>
    API.getCurrentOrganization()
      .then(({ data, status }: { data: { id: number }; status: number }) => {
        if (status === 200) {
          setOrgId(data.id)
        }
      })
      .catch((error: any) => console.error(error))

  const fetchParties = useCallback(
    () =>
      API.getAllParties(event?.event)
        .then(({ data, status }: { data: Party[]; status: number }) => {
          if (status === 200) {
            data.sort((current, next) => (current.added < next.added ? -1 : 1))
            setParties(data)
            updateCallback()
          }
        })
        .catch((error: any) => console.error(error)),
    [event, updateCallback]
  )

  const updateParty = (party: Party) =>
    API.putParty(party.guestlist, party.id, party)
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          fetchParties()
        }
      })
      .catch((error: any) => console.error(error))

  const cancelParty = (party: Party) =>
    API.cancelParty(party.guestlist, party.id, party)
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          fetchParties()
        }
      })
      .catch((error: any) => console.error(error))

  const fetchCheckinUrl = useCallback(
    () =>
      orgId &&
      API.getCheckInAppUrlGuests(event?.event)
        .then(
          ({
            data,
            status
          }: {
            data: { relative: string }
            status: number
          }) => {
            if (status === 200) {
              setCheckinUrl(data.relative)
            }
          }
        )
        .catch((error: any) => console.error(error)),
    [orgId, event]
  )

  useEffect(() => {
    if (event) {
      fetchParties()
      fetchOrgId()
    }
  }, [event?.event])

  useEffect(() => {
    if (event) {
      fetchCheckinUrl()
    }
  }, [orgId])

  const fields = event?.fields || []

  const state: State = { parties, checkinUrl, fields }
  const handlers: Handlers = { updateParty, cancelParty }

  return [state, handlers]
}
