/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import InputContainer from 'components/Inputs/Container'
import Number from 'components/Inputs/Number'
import Text from 'components/Inputs/Text'
import { FieldType } from 'misc/field.types'
import { FieldValue, FieldWithValue } from '../types'
import { fieldValidators } from './EditGuestDetailsModal.validation'
import { CheckBox } from './CheckBox'
import { CheckBoxWithOptionName } from './CheckBoxWithOptionName'
import { Dropdown } from './Dropdown'

interface FieldFactoryProps {
  field: FieldWithValue
  onChange: (value: FieldValue, error: string | null) => void
  error: string | null
}
export const FieldFactory: React.FC<FieldFactoryProps> = ({
  field,
  onChange,
  error
}) => {
  const getValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value === '') return undefined

    return value
  }

  const handleChange = (value: FieldValue) => {
    // check if required field is empty
    if (field.required && !value) {
      onChange(value, 'This field is required')
      return
    }

    const schema = fieldValidators[field.type]

    if (schema) {
      const { success, error } = schema.safeParse(value)

      if (!success) {
        onChange(value, error.errors[0].message)
        return
      } else {
        onChange(value, null)
        return
      }
    }

    onChange(value, null)
    return
  }

  const getInputComponent = (field: FieldWithValue) => {
    switch (field.type) {
      case FieldType.COMPANY_NAME:
      case FieldType.EMAIL:
      case FieldType.FIRST_NAME:
      case FieldType.LAST_NAME:
      case FieldType.PHONE_NUMBER:
      case FieldType.TEXT:
        return (
          <Text
            value={
              field.value !== null && field.value !== undefined
                ? field.value.toString()
                : undefined
            }
            placeholder={field.label}
            id={field.id.toString()}
            handleChange={e => handleChange(getValue(e))}
            errored={!!error}
          />
        )
      case FieldType.NUMBER:
        const handleNumberChange = (value: string | undefined) => {
          if (!value) {
            handleChange(undefined)
            return
          }

          handleChange(parseInt(value))
        }

        return (
          <Number
            value={
              field.value !== null && field.value !== undefined
                ? parseInt(field.value as string)
                : undefined
            }
            placeholder={field.label}
            id={field.id.toString()}
            handleChange={e => handleNumberChange(getValue(e))}
            errored={!!error}
          />
        )
      case FieldType.BOOL:
        return (
          <CheckBox
            checked={
              field.value !== null && field.value !== undefined
                ? !!field.value
                : false
            }
            onChange={(checked: boolean) => handleChange(!!checked)}
          />
        )
      case FieldType.SELECT_MULTIPLE_CHOICE:
        return (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              justify-content: center;
              align-items: flex-start;
            `}>
            {field.data?.options.map((option: { value: string }, index) => (
              <CheckBoxWithOptionName
                key={index}
                onChange={(checked: boolean) => {
                  const value = (field.value as unknown) as string[]
                  const newValue = checked
                    ? [...value, option.value]
                    : value.filter(v => v !== option.value)

                  handleChange(newValue)
                }}
                checked={
                  field.value !== null && field.value !== undefined
                    ? ((field.value as unknown) as string[]).includes(
                        option.value
                      )
                    : false
                }
                text={option.value}
              />
            ))}
          </div>
        )
      case FieldType.SELECT_SINGLE_CHOICE:
        return (
          <Dropdown
            // go through field.data.options for label and value
            options={
              field.data?.options.map(option => ({
                label: option.value,
                value: option.value
              })) || []
            }
            selectedValue={
              field.value !== null && field.value !== undefined
                ? field.value.toString()
                : undefined
            }
            onChange={handleChange}
          />
        )
      default:
        return <div>Unsupported field type</div>
    }
  }

  const label = field.required ? `${field.label} *` : field.label

  return (
    <InputContainer id={field.id.toString()} label={label}>
      <div>
        {getInputComponent(field)}
        <p
          css={css`
            margin-top: 9px;
            color: #fe2f1f;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            opacity: ${error ? 1 : 0};
          `}>
          {error || <>&nbsp;</>}
        </p>
      </div>
    </InputContainer>
  )
}
