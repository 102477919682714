import './Actions.scss'
import React, { useState } from 'react'
import clsx from 'clsx'

// Components
import Button from 'components/Button'
import {
  IconCheckmark,
  IconCancel,
  IconDelete,
  IconUndo,
  IconGroupCancel,
  IconGroupEdit,
  IconGroupRestore
} from 'components/AccordionTable/Accordion/Guest/icons'

// Modals
import { ManagePartyModal } from 'components/Modals/ManagePartyModal'

// Translation
import { useTranslation } from 'react-i18next'
import { Guest, Party } from 'misc/api/types'
import { Field } from 'misc/api/hooks/event.types'
import { EditGuestDetailsModal } from 'components/Modals/ManagePartyModal/EditGuestDetails/EditGuestDetailsModal'
import { FieldValue } from 'components/Modals/ManagePartyModal/types'

type ManagePartyUiState =
  | { step: 'hidden' }
  | { step: 'party-overview' }
  | {
      step: 'edit-guest-details'
      payload: {
        fields: Field[]
        isLeader: boolean
        guest: Guest
      }
    }

interface ManagePartyProps {
  partyId: number
  party: Party['guests']
  leader: Guest
  leaderFields: Field[]
  followerFields: Field[]
  updateGuestDetails: (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => void
}
const ManageParty = ({
  partyId,
  party,
  leader,
  leaderFields,
  followerFields,
  updateGuestDetails
}: ManagePartyProps) => {
  const [managePartyUiState, setManagePartyUiState] = useState<
    ManagePartyUiState
  >({ step: 'hidden' })
  const { t } = useTranslation()

  return (
    <div>
      <Button
        type="secondary"
        onClick={() => setManagePartyUiState({ step: 'party-overview' })}>
        <IconGroupEdit className="AccordionPartyIcon" />
        {t('manage party')}
      </Button>

      {managePartyUiState.step === 'party-overview' && (
        <ManagePartyModal
          handleCloseModal={() => setManagePartyUiState({ step: 'hidden' })}
          leader={leader}
          party={party}
          leaderFields={leaderFields}
          followerFields={followerFields}
          onEditClick={(isLeader: boolean, guest: Guest) =>
            setManagePartyUiState({
              step: 'edit-guest-details',
              payload: {
                fields: isLeader ? leaderFields : followerFields,
                isLeader,
                guest
              }
            })
          }
        />
      )}
      {managePartyUiState.step === 'edit-guest-details' && (
        <EditGuestDetailsModal
          handleCloseModal={() =>
            setManagePartyUiState({ step: 'party-overview' })
          }
          fields={managePartyUiState.payload.fields}
          isLeader={managePartyUiState.payload.isLeader}
          guest={managePartyUiState.payload.guest}
          onChangesSaved={fields => {
            updateGuestDetails(
              managePartyUiState.payload.guest.id,
              partyId,
              fields
            )
          }}
        />
      )}
    </div>
  )
}

interface PartyActionsProps {
  partyId: number
  handleCancelParty: (partyId: number) => void
  party: Party['guests']
  leader: Guest
  leaderFields: Field[]
  followerFields: Field[]
  canceled: boolean
  handleRestoreParty: (partyId: number) => void
  canRestore: boolean
  updateGuestDetails: (
    guestId: number,
    partyId: number,
    guestData: Record<string, FieldValue>
  ) => void
}

export const PartyActions = ({
  handleCancelParty,
  partyId,
  party,
  leader,
  leaderFields,
  followerFields,
  canceled,
  handleRestoreParty,
  canRestore,
  updateGuestDetails
}: PartyActionsProps) => {
  const { t } = useTranslation()

  return (
    <div className="PartyActions">
      <ManageParty
        party={party}
        leader={leader}
        leaderFields={leaderFields}
        followerFields={followerFields}
        updateGuestDetails={updateGuestDetails}
        partyId={partyId}
      />

      <Button
        type="secondary"
        disabled={canceled ? !canRestore : false}
        onClick={(canceled ? handleRestoreParty : handleCancelParty).bind(
          null,
          partyId
        )}>
        {canceled ? (
          <IconGroupRestore className="AccordionPartyIcon" />
        ) : (
          <IconGroupCancel className="AccordionPartyIcon" />
        )}

        {t(canceled ? 'restore party' : 'cancel party')}
      </Button>
    </div>
  )
}

interface GuestActionsProps {
  handleDeleteGuest: (guestId: number, partyId: number) => void
  handleToggleGuestCancel: (guestId: number, partyId: number) => void
  leader: boolean
  guestId: number
  partyId: number
  eventFull: boolean
  guest: Guest
}
export const GuestActions = ({
  eventFull,
  guest,
  guestId,
  partyId,
  handleDeleteGuest,
  handleToggleGuestCancel
}: GuestActionsProps) => {
  const { t } = useTranslation()
  const { canceled, checked_in, guest_type } = guest

  return (
    <div className="GuestActions">
      <div className="checkin">
        {checked_in && <IconCheckmark handleClick={undefined} />}
      </div>
      <div className="actions">
        {canceled ? (
          <CanceledActions
            handleDeleteGuest={handleDeleteGuest}
            handleToggleGuestCancel={handleToggleGuestCancel}
            leader={guest_type === 'leader'}
            guestId={guestId}
            partyId={partyId}
            eventFull={eventFull}
            data-width="full"
          />
        ) : (
          <button
            className="ActionsButton"
            onClick={() => handleToggleGuestCancel(guestId, partyId)}
            title={t('cancelGuest')}>
            <IconCancel fill={undefined} />
          </button>
        )}
      </div>
    </div>
  )
}

interface CanceledActionsProps {
  handleDeleteGuest: (guestId: number, partyId: number) => void | Promise<void>
  handleToggleGuestCancel: (
    guestId: number,
    partyId: number
  ) => void | Promise<void>
  leader: boolean
  partyId: number
  guestId: number
  eventFull: boolean
}
const CanceledActions = ({
  eventFull,
  guestId,
  handleDeleteGuest,
  handleToggleGuestCancel,
  leader,
  partyId
}: CanceledActionsProps) => {
  const { t } = useTranslation()

  return (
    <div className="CanceledActions">
      {!leader && (
        <IconDelete handleClick={() => handleDeleteGuest(guestId, partyId)} />
      )}
      <button
        className={clsx(['ActionsButton', { 'is-disabled': eventFull }])}
        disabled={eventFull}
        onClick={() => handleToggleGuestCancel(guestId, partyId)}
        title={t('restoreGuest')}>
        <IconUndo />
      </button>
    </div>
  )
}
