type FieldId = string

export interface ValidationErrorDetails {
  validationErrors: Record<FieldId, ValidationErrorEntry>
  statusCode: number
}

export interface ValidationErrorEntry {
  error:
    | string
    | {
        available_options: string[]
        invalid_options: string
      }
  guest_data_key: string
  invalid_data: any
}

export class ValidationError extends Error {
  statusCode: number
  validationErrors: Record<string, ValidationErrorEntry>

  constructor(details: ValidationErrorDetails) {
    super(
      JSON.stringify(
        details.validationErrors[Object.keys(details.validationErrors)[0]].error
      )
    )

    this.name = 'ValidationError'
    this.statusCode = details.statusCode
    this.validationErrors = details.validationErrors
  }
}
