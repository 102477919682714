/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { CheckBox } from './CheckBox'

interface CheckBoxWithOptionNameProps {
  text: string
  onChange: (checked: boolean) => void
  checked: boolean
}

export function CheckBoxWithOptionName({
  text,
  onChange,
  checked
}: CheckBoxWithOptionNameProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
      `}>
      <CheckBox checked={checked} onChange={onChange} />
      <span
        css={css`
          color: #151515;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px; /* 100% */
        `}>
        {text}
      </span>
    </div>
  )
}
