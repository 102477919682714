import Button from 'components/Button'
import { Actions, Content, Header, Modal } from 'components/Modals/Modal'

import { Field } from 'misc/api/hooks/event.types'
import { Guest } from 'misc/api/types'
import { useTranslation } from 'react-i18next'
import { GuestComponent } from './GuestComponent'
import { formatFields } from './helper'

interface ManagePartyModalProps {
  leader: Guest
  party: Guest[]
  leaderFields: Field[]
  followerFields: Field[]
  handleCloseModal: () => void
  onEditClick: (isLeader: boolean, guest: Guest) => void
}
export const ManagePartyModal = ({
  leader,
  party,
  leaderFields,
  followerFields,
  handleCloseModal,
  onEditClick
}: ManagePartyModalProps) => {
  const leaderData = formatFields(leader, leaderFields)
  const { t } = useTranslation()

  return (
    <Modal size="medium">
      <div className="ManagePartyModal">
        <Header>{t('manage party')}</Header>
        <Content>
          <GuestComponent
            guest={leaderData}
            isLeader={true}
            onEditClick={() => onEditClick(true, leader)}
          />

          {party.map((guest, index) => {
            const currentGuest = formatFields(guest, followerFields)
            return (
              <GuestComponent
                key={index}
                guest={currentGuest}
                isLeader={false}
                onEditClick={() => onEditClick(false, guest)}
              />
            )
          })}
        </Content>
        <Actions
          leftActions={
            <Button type="secondary" onClick={handleCloseModal}>
              {t('close')}
            </Button>
          }
          rightActions={undefined}
        />
      </div>
    </Modal>
  )
}

export default ManagePartyModal
