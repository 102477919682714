import { Field } from 'misc/api/hooks/event.types'
import { FieldType } from 'misc/field.types'

export const isMultiChoice = (field: Field) => {
  const fields: FieldType[] = [
    FieldType.SELECT_MULTIPLE_CHOICE,
    FieldType.SELECT_SINGLE_CHOICE
  ]

  return fields.includes(field.type)
}
