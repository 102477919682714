/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ChangeEvent, FC } from 'react'

type CheckboxProps = {
  checked: boolean
  onChange: (checked: boolean) => void
}

const containerStyle = css`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`

const checkboxStyle = css`
  appearance: none;
  width: 24px;
  height: 24px;
  padding-bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid #b7bbc4;
  cursor: pointer;

  &:checked {
    background: #297ad1;
    border: none;
  }

  &:checked::before {
    content: '';
    width: 14px;
    height: 10px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.00182 4.76809C0.669021 5.13601 0.692457 5.70964 1.05416 6.04933L4.44901 9.23754L4.4821 9.20096C4.53608 9.30518 4.60865 9.40195 4.69961 9.48617C5.12083 9.87618 5.77683 9.84951 6.16483 9.4266L12.8296 2.16204C13.2176 1.73912 13.1907 1.08011 12.7695 0.6901C12.3482 0.300084 11.6922 0.326754 11.3042 0.749666L5.16283 7.44376L2.25933 4.71698C1.89762 4.37729 1.33461 4.40017 1.00182 4.76809Z" fill="white"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`

export const CheckBox: FC<CheckboxProps> = ({ checked, onChange }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return (
    <label css={containerStyle}>
      <input
        type="checkbox"
        css={checkboxStyle}
        checked={checked}
        onChange={handleChange}
      />
    </label>
  )
}
