import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import './App.scss'
import './i18n.js'
import './misc/polyfill.js'
import { Helmet } from 'react-helmet'

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom'

import OrganizationProvider, {
  OrganizationContext
} from 'components/Contexts/OrganizationProvider'
import AuthProvider from 'components/Contexts/AuthProvider'

// Route components
// Overview
import OverviewEvents from 'routes/OverviewEvents'

// Event Specific
import EventDetail from './routes/EventDetail/index.js'

// Login
import Login from './routes/Auth/Login/index.js'

// Sms creation/edit
import SmsEdit from './routes/SmsEdit/index.js'

// Authorization
import Authorization from './routes/Auth/Authorization/index.js'

// PrivateRoutes
import PrivateRoute from './routes/Auth/PrivateRoute/index.js'

// Error handling
import ErrorProvider from './components/Contexts/ErrorProvider/index.js'

// QueryClient
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Create a client
const queryClient = new QueryClient()

export default class App extends React.Component {
  render() {
    return (
      <ErrorProvider>
        <QueryClientProvider client={queryClient}>
          <OrganizationProvider>
            <AuthProvider>
              <OrganizationContext.Consumer>
                {({ organization }) => {
                  return (
                    <Helmet
                      titleTemplate={
                        organization
                          ? `C5 - ${organization.name} - %s`
                          : undefined
                      }
                      defaultTitle={
                        organization ? `C5 - ${organization.name}` : 'C5'
                      }
                    />
                  )
                }}
              </OrganizationContext.Consumer>

              <Router>
                <Switch>
                  <Route
                    exact
                    path={'/oauth/authorize'}
                    render={() => <Authorization />}
                  />
                  <Route path={'/login'} render={() => <Login />} />

                  <PrivateRoute
                    exact
                    path="/"
                    render={() => <Redirect to={'/events/start'} />}
                  />
                  <PrivateRoute
                    exact
                    path="/events"
                    render={() => <Redirect to={'/events/start'} />}
                  />
                  <PrivateRoute
                    path={'/events/:type'}
                    render={({ match }: { match: any }) => (
                      <OverviewEvents match={match} />
                    )}
                  />
                  <PrivateRoute
                    path={'/event/:eventId/sms/:id?'}
                    render={() => <SmsEdit />}
                  />
                  <PrivateRoute
                    path={'/event/:id/:mode'}
                    render={() => <EventDetail />}
                  />
                  <PrivateRoute
                    path={'/event/:eventId/sms/:id?'}
                    render={() => <SmsEdit />}
                  />
                </Switch>
              </Router>
            </AuthProvider>
          </OrganizationProvider>
        </QueryClientProvider>
      </ErrorProvider>
    )
  }
}
