import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './Dropdown.scss'

const handlerOptions = { capture: true }

const KEYCODES_UP_DOWN = [38, 40]

interface DropdownProps {
  children: (props: {
    handleSelect: (item: any) => void
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    selectedIndex: number
    setDropdownItems: React.Dispatch<React.SetStateAction<Array<any>>>
  }) => React.ReactNode
  className?: string
  items?: Array<any>
  onSelect?: (item: any) => void
  [key: string]: any
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  className,
  items = [],
  onSelect,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [dropdownItems, setDropdownItems] = useState(items)
  const rootElem = useRef<HTMLDivElement>(null)

  const handleClick = (event: MouseEvent) => {
    let inspect = event.target as Node | null

    while (inspect && inspect.nodeType === Node.ELEMENT_NODE) {
      if (inspect === rootElem.current) {
        return
      }

      inspect = inspect.parentNode
    }

    setIsOpen(false)
  }

  const handleSelect = useCallback(
    (item: any) => {
      if (typeof onSelect === 'function') {
        setIsOpen(false)
        onSelect(item)
      }
    },
    [onSelect]
  )

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
        return
      }

      if (
        dropdownItems.length > 1 &&
        KEYCODES_UP_DOWN.indexOf(event.which) !== -1
      ) {
        event.preventDefault()

        const [UP, DOWN] = KEYCODES_UP_DOWN

        if (event.which === DOWN) {
          return setSelectedIndex(i => (i >= dropdownItems.length ? 0 : i + 1))
        }

        if (event.which === UP) {
          return setSelectedIndex(i =>
            i <= 0 ? dropdownItems.length - 1 : i - 1
          )
        }
      }

      if (event.which === 13 && dropdownItems.length > 0) {
        return handleSelect(dropdownItems[selectedIndex])
      }

      if (event.which === 27) {
        return setIsOpen(false)
      }
    }

    const addEventListeners = () => {
      document.addEventListener('click', handleClick, handlerOptions)
      document.addEventListener('keydown', handleKeydown, handlerOptions)
    }

    const removeEventListeners = () => {
      document.removeEventListener('click', handleClick, handlerOptions)
      document.removeEventListener('keydown', handleKeydown, handlerOptions)
    }

    if (isOpen) {
      addEventListeners()
    } else {
      removeEventListeners()
    }

    return removeEventListeners
  }, [isOpen, setIsOpen, dropdownItems, selectedIndex, handleSelect])

  useEffect(() => {
    setSelectedIndex(0)
  }, [isOpen, dropdownItems])

  return (
    <div className={clsx('Dropdown', className)} ref={rootElem} {...props}>
      {children({
        handleSelect,
        isOpen,
        setIsOpen,
        selectedIndex,
        setDropdownItems
      })}
    </div>
  )
}

export default Dropdown
