import './SettingsModule.scss'
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

export const SettingsModule = ({
  children,
  className,
  description,
  title,
  rightContent,
  size
}) => {
  return (
    <div className={clsx('SettingsModule', className)} data-size={size}>
      {title && (
        <div className="SettingsModuleHeader SettingsModuleInset">
          <div className="SettingsModuleHeaderInner">
            <div
              className="SettingsModuleHeaderInnerContainer"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                  fontSize: '14px',
                  flex: '1 1 auto'
                }}>
                {title}
              </h3>
              {description && (
                <p className="SettingsModuleDescription">{description}</p>
              )}
            </div>
            {rightContent && <div className="content">{rightContent}</div>}
          </div>
        </div>
      )}

      {children && (
        <div className="SettingsModuleContent SettingsModuleInset">
          {children}
        </div>
      )}
    </div>
  )
}

export default SettingsModule

SettingsModule.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string
}
