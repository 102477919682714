const tokenStorageKey = 'bearerToken'

/**
 * @param {Token|null} token
 */
export const isTokenValid = token => {
  if (!token) return false

  const { expires_at: expires_at_in_mins } = token
  const expires_at_in_ms = expires_at_in_mins * 1000
  const now = new Date().getTime()

  return now < expires_at_in_ms
}

/**
 * This function will return the token stored in the sessionStorage.
 * If the token is no longer valid, it will be removed from the sessionStorage and null will be returned.
 * @typedef {Object} Token
 * @property {number} expires_at
 * @property {number} expires_in
 * @property {string} scope
 * @property {number} status
 * @property {string} token
 * @property {string} token_type
 * @returns {Token|null}
 */
export const getStoredToken = () => {
  const token = JSON.parse(sessionStorage.getItem(tokenStorageKey)) || null

  if (!token) {
    return null
  }

  if (!isTokenValid(token)) {
    clearToken()
    return null
  }

  return token
}

/**
 * @param {Token} token
 */
export const storeToken = token => {
  sessionStorage.setItem(tokenStorageKey, JSON.stringify(token))
}

export const clearToken = () => {
  sessionStorage.removeItem(tokenStorageKey)
}
