/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, ChangeEvent } from 'react'

type DropdownProps = {
  options: Array<{ label: string; value: string }>
  selectedValue?: string
  onChange: (value: string | undefined) => void
  placeholder?: string
}

const containerStyle = css`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #d6dbe6;
  background: #fff;
  position: relative;
  cursor: pointer;
`

const selectStyle = css`
  appearance: none;
  border: none;
  background: transparent;
  width: 100%;
  font-size: 14px;
  color: #333;
  outline: none;
  cursor: pointer;
  padding-right: 24px; /* Space for the chevron */
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
`

const chevronStyle = css`
  position: absolute;
  right: 12px;
  pointer-events: none;
`

export const Dropdown: FC<DropdownProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder = 'Select an option'
}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    onChange(value === '' ? undefined : value)
  }

  return (
    <div css={containerStyle}>
      <select
        css={selectStyle}
        value={selectedValue ?? ''}
        onChange={handleChange}>
        <option value="" disabled>
          {placeholder}
        </option>
        <option value="">-- Clear Selection --</option> {/* Clear option */}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div css={chevronStyle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none">
          <path
            d="M4.29289 5.29289L0.707107 1.70711C0.0771419 1.07714 0.523309 0 1.41421 0H8.58579C9.47669 0 9.92286 1.07714 9.29289 1.70711L5.70711 5.29289C5.31658 5.68342 4.68342 5.68342 4.29289 5.29289Z"
            fill="#DFE5EB"
          />
        </svg>
      </div>
    </div>
  )
}
