/** @jsxImportSource @emotion/react */

import React from 'react'
import { css } from '@emotion/react'

interface SpinnerProps {
  size?: number // Size of the spinner in pixels
  color?: string // Color of the spinner
}

const Spinner: React.FC<SpinnerProps> = ({ size = 40, color = '#3498db' }) => {
  const spinnerStyle = css`
    display: inline-block;
    width: ${size}px;
    height: ${size}px;
    border: ${size / 8}px solid ${color};
    border-top: ${size / 8}px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `

  return <div css={spinnerStyle} />
}

export default Spinner
