import { Party } from 'misc/api/types'
import { useState } from 'react'

type UseSearchReturn = [
  { search: string },
  {
    setSearch: (search: string) => void
    filterGuests: (parties: Party[]) => Party[]
  }
]
export const useSearch = (): UseSearchReturn => {
  const [search, setSearch] = useState('')

  const checkGuests = (guests: Party['guests']) => {
    const matchingGuests = guests.filter(guest => checkGuestData(guest))
    return matchingGuests.length > 0
  }

  const checkGuestData = (guest: Party['guests'][0]) =>
    Object.values(guest.guest_data).some(value =>
      typeof value === 'string'
        ? value.toLowerCase().includes(search.toLowerCase())
        : false
    )

  const filterGuests = (parties: Party[]) =>
    search.length === 0
      ? parties
      : parties.filter(party => checkGuests(party.guests))

  const state = { search }
  const handlers = { setSearch, filterGuests }
  return [state, handlers]
}
