export const theme = {
  unit: 8, // Base unit in pixels

  units: {
    unit100: 8 / 2, // 4px
    unit200: 8, // 8px
    unit300: 8 * 2, // 16px
    unit400: 8 * 3, // 24px
    unit500: 8 * 4, // 32px
    unit600: 8 * 5, // 40px
    unit700: 8 * 8, // 64px
    unit800: 8 * 12, // 96px
    unit900: 8 * 20 // 160px
  },

  container: {
    width: 1280 // 1280px
  },

  editor: {
    headerHeight: 120 // 120px
  },

  colors: {
    ink: '#151515',
    light: '#ffffff',

    blue: {
      base: '#4499f5',
      100: '#76b7f8',
      200: '#5ea8f7',
      300: '#529ff6',
      400: '#4499f5',
      500: '#3a87d8',
      600: '#3277bd',
      700: '#2a66a2',
      800: '#235788',
      900: '#174068'
    },

    darkblue: {
      base: '#0d325b',
      100: '#2d567f',
      200: '#244b74',
      300: '#1d426a',
      400: '#0d325b',
      500: '#0b2c52',
      600: '#09264a',
      700: '#071f40',
      800: '#051936',
      900: '#03122a'
    },

    red: {
      base: '#f84f6e',
      100: '#fb9aa8',
      200: '#f98192',
      300: '#f8697c',
      400: '#f84f6e',
      500: '#d9475f',
      600: '#bd3f54',
      700: '#a23648',
      800: '#882d3d',
      900: '#682229'
    },

    green: {
      base: '#29c166',
      100: '#a4e5c1',
      200: '#85dcb0',
      300: '#66d39f',
      400: '#29c166',
      500: '#24aa5b',
      600: '#209451',
      700: '#1b7d46',
      800: '#17663b',
      900: '#12512f'
    },

    grey: {
      base: '#8c8f95',
      100: '#d7d8da',
      200: '#c1c3c6',
      300: '#abadb2',
      400: '#8c8f95',
      500: '#7b7d82',
      600: '#6b6d71',
      700: '#5a5c60',
      800: '#4a4b4f',
      900: '#393a3d'
    },

    white: {
      100: '#ffffff',
      200: '#fdfdfd',
      300: '#f7f7f7',
      400: '#f2f2f2',
      500: '#ececec',
      600: '#e5e5e5',
      700: '#dfdfdf',
      800: '#d8d8d8',
      900: '#d1d1d1'
    },

    purple: {
      base: '#8591b4',
      100: '#e5e8f1',
      200: '#dfe2eb',
      300: '#c9cfe0',
      400: '#8591b4',
      500: '#747fa0',
      600: '#636e8b',
      700: '#525c76',
      800: '#414a62',
      900: '#31384d'
    },

    orange: {
      base: '#ffa061',
      100: '#ffdcc6',
      200: '#ffceb0',
      300: '#ffc09a',
      400: '#ffa061',
      500: '#e69057',
      600: '#cc804e',
      700: '#b37044',
      800: '#995f3b',
      900: '#804f31'
    },

    assignments: {
      primary: '#3f90e6',
      primaryDisabled: '#2e70b7',
      primaryHover: '#4499f5',
      secondary: '#224b78',
      secondaryHover: '#4a6684',
      secondaryBright: '#f0f4f8',
      secondarySemi: '#284970',
      secondaryDark: '#0d325b',
      dark: '#082443',
      darkHover: '#0a2a4c',
      toolbar: '#547497',
      toolbarHover: '#3a6798',
      warning: '#e21042',
      focus: '#2297ff',
      success: '#29c166',
      successHover: '#3dd67a',
      draft: '#ec644b',
      closed: '#3c5a7b',
      active: '#15b75a',
      full: '#ef8a00'
    },

    text: {
      color: '#151515'
    },

    common: {
      white: '#ffffff',
      black: '#151515'
    }
  }
}

export default theme
