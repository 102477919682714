import React from 'react'
import { createRoot } from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import detectInputType from './misc/detectInputType'
import { getStoredToken } from './misc/api/storedToken'
import { setAuthorizationHeader } from './misc/api/util'

/**
 * This function will set the Authorization header in the axios instance if the stored token is valid.
 * It needs to be called before making any request that requires authentication, otherwise we get a 401 response and the user is redirected to the login page.
 */
export const setupAuthorizationHeaderIfStoredTokenIsValid = () => {
  const storedToken = getStoredToken()

  if (storedToken) {
    setAuthorizationHeader({ data: storedToken })
  }
}

setupAuthorizationHeaderIfStoredTokenIsValid()
detectInputType()

const {
  REACT_APP_BUGSNAG_API_KEY,
  REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES,
  REACT_APP_BUGSNAG_RELEASE_STAGE
} = process.env

Bugsnag.start({
  apiKey: REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES.split(','),
  releaseStage: REACT_APP_BUGSNAG_RELEASE_STAGE
})

const ErrorBoundary = Bugsnag.getPlugin('react')
const domContainer = document.getElementById('root')

const root = createRoot(domContainer)

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
