import { Field } from 'misc/api/hooks/event.types'
import { Guest } from 'misc/api/types'
import { FormattedFields } from './types'

export const getConnectedFields = (guest: Guest, fields: Field[]) => {
  const connectedFields = fields
    .map(field => ({
      ...field,
      value: guest.guest_data[field.key]
    }))
    .sort((curr, next) => curr.order_index - next.order_index)

  return connectedFields
}

export const formatFields = (
  guest: Guest,
  fields: Field[]
): FormattedFields => {
  // Connnect fields and guest_data, and sort result after the order_index
  const connectedFields = getConnectedFields(guest, fields)

  // Checks if the title fields exist
  const titleFieldTypes = ['FIRST_NAME', 'LAST_NAME']
  const titleFields = connectedFields.filter(field =>
    titleFieldTypes.includes(field.type)
  )

  // Sort out fields that is not part of the title
  const dataFields = connectedFields.filter(
    field => titleFieldTypes.includes(field.type) === false
  )

  // Connect title string
  const title = titleFields
    .reduce((prev, field) => `${prev} ${field.value}`, '')
    .trim()

  return {
    title,
    dataFields
  }
}
